import React, { useMemo } from 'react';
import { Routes, Route } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'

import Ind40MachineListPage from './machine/List'
import Ind40MachineDatesPage from './plans/Dates'
import Ind40MachinePlansPage from './plans/Plans'
import Ind40ProgramTree from './programs/Tree'
import { faConveyorBelt, faListCheck } from '@fortawesome/pro-solid-svg-icons';

const Router = () => (
	<Routes>
		<Route path="/machine/plans/:machine/:date" element={<Ind40MachinePlansPage />} />
		<Route path="/machine/plans/:machine" element={<Ind40MachineDatesPage />} />
		<Route path="/machine/list" element={<Ind40MachineListPage />} />

		<Route path="/task/list" element={<Ind40ProgramTree />} />
		
		<Route path="/" element={<Blank />} />
	</Routes>
)

const Blank = () => (
	<Tpl title="Foresti e Suardi" />
)

const useMenu = (api) => useMemo(() => {
	const menu = []
	if(api.auth.chk('ind40'))
		menu.push(
			{ label:"Macchine ind 4.0", linkTo:"/machine/list", icon:faConveyorBelt },
			{ label:"Programmi", linkTo:"/task/list", icon:faListCheck },
		)
	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const menu = useMenu(api)

	return (
		<APICtx.Provider value={api}>
			<Screen toolAuth menu={menu}>
				{api.chkAuth() ? <Router /> : <Blank />}
			</Screen>
		</APICtx.Provider>
	)
}